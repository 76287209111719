// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-stories-a-call-js": () => import("./../../../src/pages/stories/a-call.js" /* webpackChunkName: "component---src-pages-stories-a-call-js" */),
  "component---src-pages-stories-blabber-js": () => import("./../../../src/pages/stories/blabber.js" /* webpackChunkName: "component---src-pages-stories-blabber-js" */),
  "component---src-pages-stories-bonus-points-js": () => import("./../../../src/pages/stories/bonus-points.js" /* webpackChunkName: "component---src-pages-stories-bonus-points-js" */),
  "component---src-pages-stories-folk-js": () => import("./../../../src/pages/stories/folk.js" /* webpackChunkName: "component---src-pages-stories-folk-js" */),
  "component---src-pages-stories-hokkien-for-beginners-js": () => import("./../../../src/pages/stories/hokkien-for-beginners.js" /* webpackChunkName: "component---src-pages-stories-hokkien-for-beginners-js" */),
  "component---src-pages-stories-on-off-js": () => import("./../../../src/pages/stories/on-off.js" /* webpackChunkName: "component---src-pages-stories-on-off-js" */),
  "component---src-pages-stories-out-of-sight-js": () => import("./../../../src/pages/stories/out-of-sight.js" /* webpackChunkName: "component---src-pages-stories-out-of-sight-js" */),
  "component---src-pages-stories-road-trip-radio-js": () => import("./../../../src/pages/stories/road-trip-radio.js" /* webpackChunkName: "component---src-pages-stories-road-trip-radio-js" */),
  "component---src-pages-stories-signals-js": () => import("./../../../src/pages/stories/signals.js" /* webpackChunkName: "component---src-pages-stories-signals-js" */),
  "component---src-pages-stories-softly-sayang-js": () => import("./../../../src/pages/stories/softly-sayang.js" /* webpackChunkName: "component---src-pages-stories-softly-sayang-js" */),
  "component---src-pages-stories-the-cat-who-barks-js": () => import("./../../../src/pages/stories/the-cat-who-barks.js" /* webpackChunkName: "component---src-pages-stories-the-cat-who-barks-js" */),
  "component---src-pages-stories-voices-and-votes-js": () => import("./../../../src/pages/stories/voices-and-votes.js" /* webpackChunkName: "component---src-pages-stories-voices-and-votes-js" */),
  "component---src-pages-stories-whispers-js": () => import("./../../../src/pages/stories/whispers.js" /* webpackChunkName: "component---src-pages-stories-whispers-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

