/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import "./src/styles/navbar.css"
import "./src/styles/canvas.css"
import "./src/styles/download.css"
import "./src/styles/stories.css"
import "./src/styles/adobe-fonts.css"
import "./src/styles/screen1.css"
import "./src/styles/screen2.css"
import "./src/styles/screen4.css"
import "./src/styles/screen6.css"
import "./src/styles/screen8.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";

/* global window, document */
const scrollTo = (id) => () => {
    const el = document.querySelector(id)
    if (el) return window.scrollTo(0, el.offsetTop - 20)
    return false
}
  
export const onRouteUpdate = ({ location: { hash } }) => {
    console.log(window.location);
    if (hash) {
        window.setTimeout(scrollTo(hash), 10)
    }
}